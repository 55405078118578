import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Container } from './Scaffold'
import Icon from './Icon'
import { ICONS } from '../constants'

const Header = styled.nav`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 96px;
  padding: 0 32px;
  width: 100%;

  @media (min-width: 768px) {
    height: 128px;
  }
`

const HeaderContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const HomeLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: 500;
`

const PageLink = styled(Link)`
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  color: #545454;
  text-decoration: none;
  font-weight: 500;
  transition: color .2s ease, border-color .1s ease;

  &:not(:first-child) {
    margin-left: 24px;
  }

  &:hover {
    border-color: black;
    color: black;
  }
`

const Dropdown = styled.button`
  display: flex;
  align-items: center;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  color: #545454;
  fill: #545454;
  font-weight: 500;
  position: relative;
  text-decoration: none;
  transition: color .2s ease, border-color .1s ease;
  outline: none;

  &:not(:first-child) {
    margin-left: 24px;
  }

  &:hover {
    color: black;
    fill: black;
  }
`

const DropdownContent = styled.div`
  background-color: white;
  display: none;
  position: absolute;
  flex-direction: column;
  top: 100%;
  left: 50%;
  width: 168px;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transform: translateX(-50%);
  text-align: left;

  ${Dropdown}:hover & {
    display: flex;
  }
`

const DropdownLink = styled(Link)`
  padding: 4px 16px;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    background-color: #F5F5F5;
  }
`

export default () => {
  return (
    <Header>
      <HeaderContainer>
        <div>
          <HomeLink to="/">Faith Howley</HomeLink>
        </div>
        <NavLinks>
          <Dropdown>
            <span style={{ marginRight: 4 }}>Services</span>
            <Icon icon={ICONS.CHEVRONDOWN} size={16} colour="inherit" />
            <DropdownContent>
              <DropdownLink to="/services/audio">Radio & Podcasting</DropdownLink>
              <DropdownLink to="/services/photography">Photography</DropdownLink>
            </DropdownContent>
          </Dropdown>
          <PageLink to="/blog">Blog</PageLink>
        </NavLinks>
      </HeaderContainer>
    </Header>
  )
}
