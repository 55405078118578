import styled from 'styled-components'
import { PropTypes } from 'prop-types'

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${p => p.maxWidth ? p.maxWidth : 960}px;
  width: 100%;
`

Container.propTypes = {
  maxWidth: PropTypes.number
}
