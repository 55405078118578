import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container } from '../components/Scaffold'
import { systemFontStack } from '../utils/typography'
import 'typeface-ibm-plex-serif'

const GlobalStyle = createGlobalStyle`
	a {
		color: inherit;
	}

	h5 {
		color: #737373;
		font-family: ${systemFontStack.join(',')};
		font-size: 13px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}
`

const PageLayout = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
`

const Body = styled.div`
	flex: 1;
	padding: 32px;

	@media (min-width: 768px) {
		padding: 64px 32px;
	}
`

const Layout = ({ title, children }) => (
	<PageLayout>
		<GlobalStyle />
		<Helmet
			htmlAttributes={{lang: 'en'}}
			title={title}
		>
			<meta charSet="utf-8" />
			<meta name="description" content="The blog and personal site of Faith Howley - Manchester based freelance radio producer." />
			<meta name="keywords" content="Radio, Podcast, Producer, Manchester, Independent, Freelance, Photography, Editing" />
			<meta name="author" content="Faith Howley" />
			<title>{title}</title>
		</Helmet>
		<Header />
		<Body>
			<Container>{children}</Container>
		</Body>
		<Footer />
	</PageLayout>
)

Layout.propTypes = {
	title: PropTypes.string.isRequired
}

Layout.defaultProps = {
	title: 'Faith Howley'
}

export default Layout
