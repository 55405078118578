import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
`

const Path = styled.path`
  fill: ${ props => props.colour };
`

const Icon = ({ icon, size, colour }) => (
  <Svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 16 16"
  >
    <Path d={icon} colour={colour}></Path>
  </Svg>
)

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  colour: PropTypes.string
}

Icon.defaultProps = {
  size: 16,
  colour: 'black'
}

export default Icon
