import Typography from 'typography'

export const systemFontStack = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol'
]

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.5,
  scaleRatio: 3.125,
  headerFontFamily: ['IBM Plex Serif', systemFontStack],
  bodyFontFamily: systemFontStack,
  headerColor: 'hsl(0, 0%, 0%, 1)',
  bodyColor: 'hsl(0, 0%, 0%, 0.8)'
})

export default typography
