import React from 'react'
import styled from 'styled-components'
import { Container } from '../components/Scaffold'
import Icon from '../components/Icon'
import { ICONS, SOCIAL_LINKS } from '../constants'

const year  = new Date().getFullYear()

const socialLinks = [
  {
    name: "LinkedIn",
    url: SOCIAL_LINKS.LINKEDIN,
    icon: ICONS.LINKEDIN
  },
  {
    name: "Twitter",
    url: SOCIAL_LINKS.TWITTER,
    icon: ICONS.TWITTER
  },
  {
    name: "Soundcloud",
    url: SOCIAL_LINKS.SOUNDCLOUD,
    icon: ICONS.SOUNDCLOUD
  },
  {
    name: "Unsplash",
    url: SOCIAL_LINKS.UNSPLASH,
    icon: ICONS.UNSPLASH
  }
]

const emojis = [
  '🙋‍♀️',
  '👋',
  '💪',
  '👍',
  '🙌',
  '👃',
  '🐱',
  '🌱',
  '🥦',
  '🥑',
  '🍅',
  '🥥',
  '🥕',
  '🍀',
  '🎉',
  '🎧'
]

const Footer = styled.footer`
  box-sizing: border-box;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  margin-top: 40px;
  padding: 80px 32px;
  width: 100%;
`

const FooterUpper = styled.div`
  margin-bottom: 0;
`

const FooterCaption = styled.h3`
  font-size: 40px;

  @media (min-width: 768px) {
    font-size: 50px;
  }
`

const FooterLower = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 80px;
`

const FooterLink = styled.a`
  color: black;
  text-decoration: none;
  text-align: center;
  width: auto;
  margin-top: 40px;

  &:not(:last-child) {
    margin-right: 40px;
  }
`

export default () => (
  <Footer>
    <Container>
      <FooterUpper>
        <FooterCaption>Questions, work enquiries or just want to say hello? <a href="mailto:faithhowley@gmail.com?subject=Website enquiry">Email me</a>.</FooterCaption>
      </FooterUpper>
      <FooterLower>
        {socialLinks.map((link) => (
          <FooterLink
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            key={'link-'+link.name}
            title={link.name}
          >
            <Icon icon={link.icon} size={24} />
          </FooterLink>
        ))}
      </FooterLower>
      <FooterLower>
        <p>&copy; {year} Faith Howley. All rights reserved. {emojis[Math.floor(Math.random() * emojis.length)]}</p>
      </FooterLower>
    </Container>
  </Footer>
)
